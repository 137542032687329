import styles from './OfferDesktop.module.scss'
import Title from '../../Title/Title'
import Tag from '../../Tag/Tag'
import PropertyBlock from '../../PropertyBlock/PropertyBlock'
import LinkButton from '../../LinkButton/LinkButton'
import { Link } from 'react-router-dom'
import { ProviderProps } from '../Offer'

export default function OfferDesktop({
  item: { icon, title, url, field1, field2, field3, advertiser, field3Color, field1Replaced, id },
  tag: { text, ...restTagProps },
  template: {
    buttonText,
    field1Name,
    field2Name,
    field3Name,
    size,
    isFlexTitleContainer,
    backgroundColorCard,
    width,
  },
  onClick,
}: ProviderProps) {
  function getField1() {
    return <PropertyBlock title={field1Name} text={`${field1}`} size={'small'} />
  }

  function getField2() {
    if (field1Replaced) {
      return (
        <PropertyBlock title={field2Name} size={'small'}>
          <span className={styles.field1Replaced}>{`${field1Replaced}`}</span>{' '}
          <span className={styles.field1New}>{`${field2}`}</span>
        </PropertyBlock>
      )
    } else {
      return <PropertyBlock title={field2Name} text={`${field2}`} size={'small'} />
    }
  }

  return (
    <div className={styles.provider} style={{ backgroundColor: backgroundColorCard, width }}>
      <Link to={url} className={styles.overlay} target='_blank' rel='nofollow' />
      <div
        className={`${styles.titleContainer} ${
          isFlexTitleContainer ? styles.titleFlexContainer : ''
        } ${title.length >= 12 && isFlexTitleContainer ? styles.smallTitleBlock : ''}`}
      >
        <img className={styles.logo} src={icon} alt={`${title} logo`} />
        <Title
          className={`${styles.title} ${
            !title.includes(' ') && title.length >= 9 && isFlexTitleContainer
              ? styles.smallText
              : ''
          } ${title.length >= 12 && isFlexTitleContainer ? styles.smallBlock : ''}`}
        >
          {title}
        </Title>
      </div>
      <div className={styles.tagContainer}>
        <Tag className={styles.tag} {...restTagProps}>
          {text}
        </Tag>
      </div>
      <div className={styles.fluidContainer}>
        <div className={`${styles.firstLineProperties} ${size === 's' ? styles.sizeS : ''}`}>
          {getField1()}
          {getField2()}
        </div>
        <PropertyBlock title={field3Name} text={field3} size={'small'} colorBlock={field3Color} />
      </div>
      <div className={styles.fluidContainer}>
        <LinkButton
          className={styles.button}
          to={url}
          target='_blank'
          uiType='secondary'
          title={url}
          onClick={(e) => onClick({ event: e, url, offerId: id })}
          rel='nofollow'
        >
          {buttonText}
        </LinkButton>
      </div>
      {advertiser && (
        <div className={styles.advertiserContainer}>
          <div className={styles.advertiser}>{advertiser}</div>
        </div>
      )}
    </div>
  )
}
