import { useEffect, useState } from 'react'
import { getShowcase, getShowcaseKeys } from '../../../api/ui/showcase'
import { useNavigate } from 'react-router-dom'

export function useShowcase(category = '') {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [showcaseKeys, setShowcaseKeys] = useState([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [offerList, setOfferList] = useState([])
  const [seoBlock, setSeoBlock] = useState(null)
  async function fetchShowcaseKeys() {
    setLoading(true)
    try {
      const result = await getShowcaseKeys({ category })
      if (result && result.length) {
        setShowcaseKeys(result)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  async function fetchShowcase({ pageNum, key }: { pageNum: number; key: string }) {
    setLoading(true)
    try {
      if (showcaseKeys.length && !showcaseKeys.includes(key)) {
        navigate('/404')
      }
      const result = await getShowcase({ page: pageNum, size: 30, key })
      if (result) {
        setOfferList((state) => [...state, ...result.content.offers])
        setSeoBlock(result.content.description)
        setHasNextPage(!(result.last && result.totalPages === pageNum))
      }
    } catch (e) {
      console.error(e)
      navigate('/404')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchShowcaseKeys()
  }, [])

  return {
    isLoading,
    offerList,
    showcaseKeys,
    fetchShowcase,
    hasNextPage,
    setOfferList,
    setHasNextPage,
    seoBlock,
  }
}
