import { CardProps } from '../modules/MainPage/components/CardStack/components/Card/Card'
import { ReactComponent as CashingWallet } from 'assets/icons/cashingWallet.svg'
import { ReactComponent as Percent } from 'assets/icons/percent.svg'
import { ReactComponent as Eye } from 'assets/icons/eye.svg'
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg'
import { ReactComponent as CardAndClock } from 'assets/icons/cardAndClock.svg'
import { ReactComponent as Furniture } from 'assets/icons/furniture.svg'
import { ReactComponent as BagAndClock } from 'assets/icons/bagAndClock.svg'
import { ReactComponent as Lifebuoy } from 'assets/icons/lifebuoy.svg'
import { ReactComponent as CoinsStack } from 'assets/icons/coinsStack.svg'
import { ReactComponent as ZeroPercent } from 'assets/icons/zeroPercent.svg'
import { ReactComponent as Car } from 'assets/icons/car.svg'
import { ReactComponent as Calculator } from 'assets/icons/callculator.svg'
import { ReactComponent as FingerRight } from 'assets/icons/fingerRight.svg'
import { ReactComponent as NotePencil } from 'assets/icons/notePencil.svg'
import { ReactComponent as Lamp } from 'assets/icons/lamp.svg'
import { ReactComponent as GearWrench } from 'assets/icons/gearWrench.svg'
import { ReactComponent as CheckList } from 'assets/icons/checklist.svg'
import { ReactComponent as PercentReduce } from 'assets/icons/percentReduce.svg'
import { ReactComponent as PaymentReduce } from 'assets/icons/paymentReduce.svg'
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg'
// import { ReactComponent as Substract } from 'assets/icons/subtract.svg'
import { useState } from 'react'
// import { setActiveCard } from '../store/store'

const debtExpertExternalLink =
  'https://pxl.leads.su/click/abb8b078ba39f8bb93f275861e0ac68e?aff_sub1=snizim.ru&erid=LjN8K4Qxa'

export default function useProductsList() {
  const [activeCard, setActiveCard] = useState<number | null>(null)
  // const activeCard = useRef<string | null>()

  function onCardToggle(cardIndex: number) {
    if (activeCard === cardIndex) {
      setActiveCard(null)
    } else {
      setActiveCard(cardIndex)
    }
  }

  const refinancing: CardProps = {
    title: { text: 'Рефинансирование' },
    color: '#DED6D1',
    subtitle: {
      text: 'Подберем финансовые организации, которые готовы выдать средства на подходящих вам условиях в течение 5 минут.',
      color: '#6E6864',
    },
    items: [
      {
        icon: {
          IconJsx: CashingWallet,
          color: '#000000',
        },
        content: {
          text: 'Снизим процентную ставку',
          color: '#000000',
        },
      },
      {
        icon: {
          IconJsx: Percent,
          color: '#000000',
        },
        content: {
          text: 'Сгруппируем ваш долг в один',
          color: '#000000',
        },
      },
      {
        icon: {
          IconJsx: Eye,
          color: '#000000',
        },
        content: {
          text: 'Досрочно погасим займ',
          color: '#000000',
        },
      },
    ],
    footerButton: {
      content: 'Подобрать условия',
      to: 'https://budgett.ru/sign_up',
    },
    onToggle: () => onCardToggle(0),
    isOpen: activeCard === 0,
    // onToggle: () => onToggle('Рефинансирование'),
  }

  const lending: CardProps = {
    title: { text: 'Кредитование' },
    color: '#B39D8F',
    link: '/showcase/credits',
    subtitle: {
      text: 'Покажем точную ставку, сумму и платеж по кредиту от нескольких банков с решением в течение 5 минут.',
      color: '#57514D',
    },
    items: [
      {
        icon: {
          IconJsx: Wallet,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#6B6059',
        },
        content: {
          text: 'Потребительские кредиты',
          color: '#000000',
          hoverColor: '#453854',
        },
        link: '/showcase/credits/consumer-loan/',
      },
      {
        icon: {
          IconJsx: CardAndClock,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#6B6059',
        },
        content: {
          text: 'Кредитные карты',
          color: '#000000',
          hoverColor: '#453854',
        },
        link: '/showcase/credits/credit-card/',
      },
      {
        icon: {
          IconJsx: Furniture,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#6B6059',
        },
        content: {
          text: 'Ипотечное кредитование',
          color: '#000000',
          hoverColor: '#453854',
        },
        link: '/showcase/credits/mortgage/',
      },
      {
        icon: {
          IconJsx: BagAndClock,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#6B6059',
        },
        content: {
          text: 'Кредитный мастер',
          color: '#000000',
          hoverColor: '#453854',
        },
        link: 'https://budgett.ru/sign_up',
      },
    ],
    onToggle: () => onCardToggle(1),
    isOpen: activeCard === 1,
    // onToggle: () => onToggle('Кредитование'),
  }

  const loans: CardProps = {
    title: { text: 'Займы', color: '#FFFFFF' },
    color: '#453854',
    subtitle: {
      text: 'Оформите займ онлайн на карту от лучших МФО с суммой до 300.000 рублей на Снизим.ру',
      color: '#A39CAA',
    },
    link: '/showcase/loans',
    items: [
      {
        icon: {
          IconJsx: PercentReduce,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Снизим процент',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/snizim-percent/',
      },
      {
        icon: {
          IconJsx: PaymentReduce,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Снизим платеж',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/snizim-payment/',
      },
      {
        icon: {
          IconJsx: Calendar,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Займы на долгий срок',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/long-term/',
      },
      {
        icon: {
          IconJsx: Lifebuoy,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Краткосрочные займы',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/short-term/',
      },
      {
        icon: {
          IconJsx: CoinsStack,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Займы на крупную сумму',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/big-amount/',
      },
      {
        icon: {
          IconJsx: ZeroPercent,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Под ноль процентов',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/zero-percents/',
      },
      {
        icon: {
          IconJsx: Car,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Займы под ПТС',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: '/showcase/loans/pts-loan/',
      },
      {
        icon: {
          IconJsx: BagAndClock,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Займ-мастер',
          color: '#FFFFFF',
          hoverColor: '#35B879',
        },
        link: 'https://budgett.ru/sign_up',
      },
      // {
      //   icon: {
      //     IconJsx: Substract,
      //     outline: 'rgba(255, 255, 255, 0.3)',
      //     color: '#FFFFFF',
      //   },
      //   content: {
      //     text: 'Рейтинг МФО',
      //     color: '#FFFFFF',
      //     hoverColor: '#35B879',
      //   },
      //   link: '/mfo',
      // },
    ],
    onToggle: () => onCardToggle(2),
    isOpen: activeCard === 2,
    // onToggle: () => onToggle('Займы'),
  }

  const bankruptcy: CardProps = {
    title: { text: 'Банкротство', color: '#FFFFFF' },
    color: '#362C26',
    subtitle: {
      text: 'Расчет условий и все инструменты для оформления банкротства в одном разделе. Делаем сложное - простым.',
      color: '#C6B6AC',
    },
    link: '/showcase/bankruptcy',
    items: [
      {
        icon: {
          IconJsx: Calculator,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Калькулятор банкротства',
          color: '#FFFFFF',
          hoverColor: '#A4A1A0',
        },
        link: debtExpertExternalLink,
        isExternalLink: true,
      },
      {
        icon: {
          IconJsx: FingerRight,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Банкротство онлайн',
          color: '#FFFFFF',
          hoverColor: '#A4A1A0',
        },
        link: '/showcase/bankruptcy',
      },
      {
        icon: {
          IconJsx: NotePencil,
          outline: 'rgba(255, 255, 255, 0.3)',
          color: '#FFFFFF',
        },
        content: {
          text: 'Заявка на банкротство',
          color: '#FFFFFF',
          hoverColor: '#A4A1A0',
        },
        link: debtExpertExternalLink,
        isExternalLink: true,
      },
    ],
    onToggle: () => onCardToggle(3),
    isOpen: activeCard === 3,
    // onToggle: () => onToggle('Банкротство'),
  }

  const creditHistory: CardProps = {
    title: { text: 'Кредитная история' },
    color: '#DFEB81',
    subtitle: {
      text: 'Взгляните на свою кредитную историю, оцените ваш рейтинг, исправьте кредитную историю.',
      color: '#453854',
    },
    items: [
      {
        icon: {
          IconJsx: Lamp,
          color: '#000000',
        },
        content: {
          text: 'Узнать кредитную историю',
          color: '#000000',
        },
      },
      {
        icon: {
          IconJsx: GearWrench,
          color: '#000000',
        },
        content: {
          text: 'Исправить кредитную историю',
          color: '#000000',
        },
      },
      {
        icon: {
          IconJsx: CheckList,
          color: '#000000',
        },
        content: {
          text: 'Проверка задолженностей',
          color: '#000000',
        },
      },
    ],
    footerButton: {
      content: 'Узнать свой рейтинг',
      to: 'https://budgett.ru/sign_up',
    },
    onToggle: () => onCardToggle(4),
    isOpen: activeCard === 4,
    // onToggle: () => onToggle('Кредитная история'),
  }

  const cards: CardProps[] = [refinancing, lending, loans, bankruptcy, creditHistory]

  return {
    cards,
  }
}
